import React from 'react';
import './MediaLibrary.css';




const MediaLibrary: React.FC = () => {
  return (
    <>

      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_1">
        Launch demo modal
      </button> */}

      <div className="modal fade" id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Media Library</h3>


              <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                <span className="svg-icon svg-icon-1"></span>
              </div>

            </div>

            <div className="modal-body">



              <div className="row mw-500px mb-5 MediaLibrary" data-kt-buttons="true" data-kt-buttons-target=".form-check-image, .form-check-input">

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-26.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" />
                    </div>
                  </label>
                </div>

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-3.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" id="text_wow" />
                    </div>
                  </label>
                </div>

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-26.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" />
                    </div>
                  </label>
                </div>

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-26.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" />
                    </div>
                  </label>
                </div>

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-26.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" />
                    </div>
                  </label>
                </div>

                <div className="col-4">
                  <label className="form-check-image">
                    <div className="form-check-wrapper">
                      <img src="/media/stock/600x400/img-26.jpg" />
                    </div>

                    <div className="form-check form-check-custom form-check-solid me-10">
                      <input className="form-check-input" type="checkbox" value="1" name="option2" />
                    </div>
                  </label>
                </div>

              </div>


            </div>

            <div className="modal-footer CustomizedFooter">
              <div>

                <label className="btn btn-icon uplaodBtn"
                  data-kt-image-input-action="change"
                  data-bs-toggle="tooltip"
                  data-bs-dismiss="click"
                  title="Change avatar">
                    Upload New File
                  <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                  <input type="hidden" name="avatar_remove" />

                </label>



              </div>
              <div>
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary">Done</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default MediaLibrary
