import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { Invoice } from '../modules/company/Invoice'
import { AboutUsAddNew } from '../modules/website/AboutUs/AddNew'
import { PhotoGalleryAddNew } from '../modules/website/PhotoGallery/AddNew'


export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/company/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))




  const MainSliderPage = lazy(() => import('../modules/website/MainSlider/Page'))
  const ReadAndViews = lazy(() => import('../modules/website/ReadAndViews/Page'))
  const VideosPage = lazy(() => import('../modules/website/Videos/Page'))
  const PublicationsPage = lazy(() => import('../modules/website/Publications/Page'))
  const MediaPressPage = lazy(() => import('../modules/website/MediaPress/Page'))
  const PhotoGalleryPage = lazy(() => import('../modules/website/PhotoGallery/Page'))
  const EventsPage = lazy(() => import('../modules/website/Events/Page'))
  const AboutUsPage = lazy(() => import('../modules/website/AboutUs/Page'))
  const MessagesPage = lazy(() => import('../modules/messages/Page'))
  const OrdersPage = lazy(() => import('../modules/orders/Page'))
  const SubscriptionsPage = lazy(() => import('../modules/subscriptions/Page'))
  const MediaLibraryPage = lazy(() => import('../modules/medialibrary/Page'))
  const CustomersPage = lazy(() => import('../modules/Customers/Page'))
  const InventoryPage = lazy(() => import('../modules/Inventory/Page'))


  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/messages' component={MessagesPage} />
        <Route path='/orders' component={OrdersPage} />
        <Route path='/customers' component={CustomersPage} />
        <Route path='/inventory' component={InventoryPage} />
        <Route path='/subscriptions' component={SubscriptionsPage} />
        <Route path='/media-library' component={MediaLibraryPage} />

        <Route path='/website/pages/main-slider' component={MainSliderPage} />
        <Route path='/website/pages/reads-views' component={ReadAndViews} />
        <Route path='/website/pages/videos' component={VideosPage} />
        <Route path='/website/pages/publications' component={PublicationsPage} />
        <Route path='/website/pages/media-press' component={MediaPressPage} />
        <Route path='/website/pages/photo-gallery' component={PhotoGalleryPage} />
        <Route path='/photo-gallery/contents/add-new' component={PhotoGalleryAddNew} />
        <Route path='/website/pages/events' component={EventsPage} />
        <Route path='/website/pages/about-us' component={AboutUsPage} />
        <Route path='/about-us/contents/add-new' component={AboutUsAddNew} />
        <Route path='/invoice' component={Invoice} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
