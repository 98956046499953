/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets';

import {
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3,
  ChartsWidget4,
  ChartsWidget5,
  ChartsWidget6,
  ChartsWidget7,
  ChartsWidget8,
} from '../../../../src/_metronic/partials/widgets';

import './style.css';

const DashboardPage: FC = () => (
  <>
    {/*begin::Post*/}
    <div className="post d-flex flex-column-fluid" id="kt_post">
      {/*begin::Container*/}
      <div id="kt_content_container" className="container-xxl">
        {/*begin::Row*/}
        <div className="row g-5 g-xl-10 mb-xl-10">

          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 4*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">$</span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">69,700</span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-success fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                          <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%</span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-bold fs-6">Total Earnings</span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
            </div>
            {/*end::Card widget 4*/}






            {/*begin::Card widget 5*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">1,836</span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-danger fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr065.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                          <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.2%</span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-bold fs-6">Orders This Month</span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end pt-0">
                {/*begin::Progress*/}
                <div className="d-flex align-items-center flex-column mt-3 w-100">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-boldest fs-6 text-dark">1,048 to Goal</span>
                    <span className="fw-bolder fs-6 text-gray-400">62%</span>
                  </div>
                  <div className="h-8px mx-3 w-100 bg-light-success rounded">
                    <div className="bg-success rounded h-8px" role="progressbar" style={{ width: "62%", }} ></div>
                    {/* aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" */}
                  </div>
                </div>
                {/*end::Progress*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 5*/}


          </div>

















          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
            {/*begin::Card widget 6*/}
            <div className="card card-flush h-md-50 mb-5 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center">
                    {/*begin::Currency*/}
                    <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">$</span>
                    {/*end::Currency*/}
                    {/*begin::Amount*/}
                    <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">2,420</span>
                    {/*end::Amount*/}
                    {/*begin::Badge*/}
                    <span className="badge badge-success fs-base">
                      {/*begin::Svg Icon | path: icons/duotune/arrows/arr066.svg*/}
                      <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                          <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}2.6%</span>
                    {/*end::Badge*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-bold fs-6">Average Daily Sales</span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}
              {/*begin::Card body*/}
              <div className="card-body d-flex align-items-end px-0 pb-0">
                {/*begin::Chart*/}
                <div id="kt_card_widget_6_chart" className="w-100" style={{ height: "80px" }}></div>
                {/*end::Chart*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card widget 6*/}
            {/*begin::Card widget 7*/}
            <div className="card card-flush h-md-50 mb-xl-10">
              {/*begin::Header*/}
              <div className="card-header pt-5">
                {/*begin::Title*/}
                <div className="card-title d-flex flex-column">
                  {/*begin::Amount*/}
                  <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">6.3k</span>
                  {/*end::Amount*/}
                  {/*begin::Subtitle*/}
                  <span className="text-gray-400 pt-1 fw-bold fs-6">New Customers This Year</span>
                  {/*end::Subtitle*/}
                </div>
                {/*end::Title*/}
              </div>
              {/*end::Header*/}

            </div>
            {/*end::Card widget 7*/}
          </div>
          {/*begin::Col*/}

          {/*begin::Col*/}
          <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
            {/*begin::Chart widget 3*/}
            <ChartsWidget3 className='card-xl-stretch chartcard mb-xl-8' />
            {/*end::Chart widget 3*/}
          </div>
          {/*end::Col*/}


        </div>
        {/*end::Row*/}








        {/*begin::Row*/}
        <div className="row gy-5 g-xl-10">


          {/*begin::Col*/}
          <div className="col-xl-4 mb-xl-10">
            {/*begin::Engage widget 1*/}
            <ListsWidget5 className='card-xxl-stretch' />
            {/*end::Engage widget 1*/}
          </div>
          {/*end::Col*/}




          {/*begin::Col*/}
          <div className="col-xl-8 mb-5 mb-xl-10">
            {/*begin::Table Widget 4*/}
            <div className="card card-flush h-xl-100">
              {/*begin::Card header*/}
              <div className="card-header pt-7">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-gray-800">Recent Orders</span>
                </h3>
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add a user'
                >
                  <a
                    href='#'
                    className='btn btn-sm btn-light-primary'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_invite_friends'
                  >
                    {/* <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' /> */}
                    View All
                  </a>
                </div>
                {/*end::Title*/}

              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body pt-2">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table">
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th className="min-w-100px">Order ID</th>
                      <th className="text-end min-w-100px">Created</th>
                      <th className="text-end min-w-125px">Customer</th>
                      <th className="text-end min-w-100px">Total</th>
                      <th className="text-end min-w-50px">Status</th>
                      <th className="text-end"></th>
                    </tr>
                    {/*end::Table row*/}
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="fw-bolder text-gray-600">

                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#XGY-346</a>
                      </td>
                      <td className="text-end">7 min ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Albert Flores</a>
                      </td>
                      <td className="text-end">$630.00</td>

                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#YHD-047</a>
                      </td>
                      <td className="text-end">52 min ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Jenny Wilson</a>
                      </td>
                      <td className="text-end">$25.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-primary">Confirmed</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#SRR-678</a>
                      </td>
                      <td className="text-end">1 hour ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Robert Fox</a>
                      </td>
                      <td className="text-end">$1,630.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#PXF-534</a>
                      </td>
                      <td className="text-end">3 hour ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Cody Fisher</a>
                      </td>
                      <td className="text-end">$119.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#XGD-249</a>
                      </td>
                      <td className="text-end">2 day ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Arlene McCoy</a>
                      </td>
                      <td className="text-end">$660.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#SKP-035</a>
                      </td>
                      <td className="text-end">2 day ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Eleanor Pena</a>
                      </td>
                      <td className="text-end">$290.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">Rejected</span>
                      </td>
                      
                    </tr>
                    <tr>
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary">#SKP-567</a>
                      </td>
                      <td className="text-end">7 min ago</td>
                      <td className="text-end">
                        <a href="#" className="text-gray-600 text-hover-primary">Dan Wilson</a>
                      </td>
                      <td className="text-end">$590.00</td>
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                      </td>
                      
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Table Widget 4*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}


        {/*begin::Row*/}
        <div className="row gy-5 g-xl-10">

          {/*begin::Col*/}
          <div className="col-xl-12">
            {/*begin::Table Widget 5*/}
            <div className="card card-flush h-xl-100">
              {/*begin::Card header*/}
              <div className="card-header pt-7">
                {/*begin::Title*/}
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-dark">Stock Report</span>
                  <span className="text-gray-400 mt-1 fw-bold fs-6">Total 2,356 Items in the Stock</span>
                </h3>
                {/*end::Title*/}

              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className="card-body">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                      <th className="min-w-100px">Item</th>
                      <th className="text-end pe-3 min-w-100px">Product ID</th>
                      <th className="text-end pe-3 min-w-150px">Date Added</th>
                      <th className="text-end pe-3 min-w-100px">Price</th>
                      <th className="text-end pe-3 min-w-50px">Status</th>
                      <th className="text-end pe-0 min-w-25px">Qty</th>
                    </tr>
                    {/*end::Table row*/}
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="fw-bolder text-gray-600">
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Macbook Air M1</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#XGY-356</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">02 Apr, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$1,230</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="58">
                        <span className="text-dark fw-bolder">58 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Surface Laptop 4</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#YHD-047</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">01 Apr, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$1,060</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="0">
                        <span className="text-dark fw-bolder">0 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Logitech MX 250</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#SRR-678</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">24 Mar, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$64</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="290">
                        <span className="text-dark fw-bolder">290 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">AudioEngine HD3</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#PXF-578</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">24 Mar, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$1,060</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="46">
                        <span className="text-dark fw-bolder">46 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">HP Hyper LTR</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#PXF-778</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">16 Jan, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$4500</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="78">
                        <span className="text-dark fw-bolder">78 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Dell 32 UltraSharp</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#XGY-356</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">22 Dec, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$1,060</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-warning">Low Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="8">
                        <span className="text-dark fw-bolder">8 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                    <tr>
                      {/*begin::Item*/}
                      <td>
                        <a href="../../demo1/dist/apps/ecommerce/catalog/edit-product.html" className="text-dark text-hover-primary">Google Pixel 6 Pro</a>
                      </td>
                      {/*end::Item*/}
                      {/*begin::Product ID*/}
                      <td className="text-end">#XVR-425</td>
                      {/*end::Product ID*/}
                      {/*begin::Date added*/}
                      <td className="text-end">27 Dec, 2022</td>
                      {/*end::Date added*/}
                      {/*begin::Price*/}
                      <td className="text-end">$1,060</td>
                      {/*end::Price*/}
                      {/*begin::Status*/}
                      <td className="text-end">
                        <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                      </td>
                      {/*end::Status*/}
                      {/*begin::Qty*/}
                      <td className="text-end" data-order="124">
                        <span className="text-dark fw-bolder">124 PCS</span>
                      </td>
                      {/*end::Qty*/}
                    </tr>
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Table Widget 5*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
      {/*end::Container*/}
    </div>
    {/*end::Post*/}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
