import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'


import MediaLibrary from '../../MediaLibrary/MediaLibrary'









const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Add Photo</h2>
      </div>


      <div className='fv-row mb-10'>
        <div className="image-input-wrapper h-30px">
          <label className='fs-6 fw-bold form-label required'>Banner Image</label>
        </div>
        <div className="image-input image-input-empty" data-bs-toggle="modal" data-bs-target="#kt_modal_1" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, }}>

          <div className="image-input-wrapper w-125px h-125px"></div>



          <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Change avatar">
            <i className="bi bi-pencil-fill fs-7"></i>


            {/* <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
            <input type="hidden" name="avatar_remove" /> */}

          </label>



          <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="cancel"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Cancel avatar">
            <i className="bi bi-x fs-2"></i>
          </span>

          <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Remove avatar">
            <i className="bi bi-x fs-2"></i>
          </span>

        </div>

      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Caption</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />

        <div style={{ display: "flex", justifyContent: "end", paddingTop: "6px", }}>
          <span style={{ color: "#676C7B", fontSize: "12px", }}>CHAR 0/50</span>
        </div>

        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Description</span>
        </label>

        <Field
          as='textarea'
          name='businessDescription'
          className='form-control form-control-lg form-control-solid'
          rows={3}
        ></Field>
        <div style={{ display: "flex", justifyContent: "end", paddingTop: "6px", }}>
          <span style={{ color: "#676C7B", fontSize: "12px", }}>CHAR 0/200</span>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>
      </div>


      <MediaLibrary />

    </div>
  )
}

export { Step1 }
