import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export function SignInAuthentication() {


  return (

    <div>

      <form className="form w-100 mb-10" id="kt_sing_in_two_steps_form">

        <div className="text-center mb-10">
          <img alt="Mobile" src={toAbsoluteUrl('/media/svg/misc//smartphone.svg')} className="mh-125px" />
        </div>


        <div className="text-center mb-10">

          <h1 className="text-dark mb-3">Two Step Verification</h1>


          <div className="text-muted fw-bold fs-5 mb-5">Enter the verification code we sent to</div>

          <div className="fw-bolder text-dark fs-3">******7859</div>

        </div>

        <div className="mb-10 px-md-10">

          <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">Type your 6 digit security code</div>

          <div className="d-flex flex-wrap flex-stack">
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="3" />
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="0" />
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="7" />
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="" />
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="" />
            <input type="text" data-inputmask="'mask': '9', 'placeholder': ''" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value="" />
          </div>
        </div>

        <div className="d-flex flex-center">
          <button type="button" id="kt_sing_in_two_steps_submit" className="btn btn-lg btn-primary fw-bolder">
            <span className="indicator-label">Submit</span>
            <span className="indicator-progress">Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>

      </form>

      <div className="text-center fw-bold fs-5">
        <span className="text-muted me-1">Didn’t get the code ?</span>
        <a href="#" className="link-primary fw-bolder fs-5 me-1">Resend</a>
      </div>

    </div>

  )
}



