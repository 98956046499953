import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { KTSVG } from '../../../../../_metronic/helpers'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Publish</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Visibility</label>
        <select className='form-select form-select-lg form-select-solid' aria-label="Select example">
          <option value="1">Public</option>
          <option value="2">Hidden</option>
        </select>

        <div className='text-danger mt-2'>
          <ErrorMessage name='businessType' />
        </div>
      </div>


      <div className='row fv-row'>
        <div className='col-6'>
          <div className='fv-row mb-10'>
            <label className='form-label required'>Publish</label>

            <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
            <div className='text-danger mt-2'>
              <ErrorMessage name='businessName' />
            </div>
          </div>
        </div>

        <div className='col-6'>
          <div className='fv-row mb-10'>
            <label className='form-label required'>Unpublish</label>

            <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
            <div className='text-danger mt-2'>
              <ErrorMessage name='businessName' />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="form-check form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
          <label className="form-check-label">
              Feature this on homepage
          </label>
        </div>
      </div>

    
    </div>
  )
}

export { Step2 }
