import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error404: FC = () => {
  return (
    <>
     <div className="d-flex flex-column flex-root">

			<div className="d-flex flex-column flex-center flex-column-fluid p-10">

        <img alt="Logo" src={toAbsoluteUrl('/media/illustrations/sketchy-1/18.png')} className="mw-100 mb-10 h-lg-450px" />
        
				<h1 className="fw-bold mb-10" style={{color: "#A3A3C7"}}>Seems there is nothing here</h1>

			</div>

		</div>
    </>
  )
}

export {Error404}
