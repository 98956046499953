import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


export function SignInNewPassword() {


    return (

        <div>
            <form className="form w-100" id="kt_new_password_form">

                <div className="text-center mb-10">

                    <h1 className="text-dark mb-3">Setup New Password</h1>

                    <div className="text-gray-400 fw-bold fs-4">Already have reset your password ? <a href="../../demo1/dist/authentication/layouts/basic/sign-in.html" className="link-primary fw-bolder">Sign in here</a></div>

                </div>

                <div className="mb-10 fv-row" data-kt-password-meter="true">

                    <div className="mb-1">

                        <label className="form-label fw-bolder text-dark fs-6">Password</label>

                        <div className="position-relative mb-3">
                            <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                                <i className="bi bi-eye-slash fs-2"></i>
                                <i className="bi bi-eye fs-2 d-none"></i>
                            </span>
                        </div>

                        <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                        </div>

                    </div>

                    <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>

                </div>

                <div className="fv-row mb-10">
                    <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
                    <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="confirm-password" />
                </div>

                <div className="fv-row mb-10">
                    <div className="form-check form-check-custom form-check-solid form-check-inline">
                        <input className="form-check-input" type="checkbox" name="toc" value="1" />
                        <label className="form-check-label fw-bold text-gray-700 fs-6">I Agree &amp;
                            <a href="#" className="ms-1 link-primary">Terms and conditions</a>.</label>
                    </div>
                </div>

                <div className="text-center">
                    <button type="button" id="kt_new_password_submit" className="btn btn-lg btn-primary fw-bolder">
                        <span className="indicator-label">Change Password</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                </div>

            </form>

        </div>

    )
}



